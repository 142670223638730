const MAX_ABOUT_ME_LENGTH = 150;
const MAX_REVIEW_LENGTH = 250;

function processAboutMeForProfiles(data) {
  if (!data) {
    return [];
  }

  const profiles = [];

  for (let i = 0; i < data.length; i++) {
    const profile = data[i];
    if (
      profile &&
      profile.myDetails &&
      profile.myDetails[0] &&
      profile.myDetails[0].about_me?.length > MAX_ABOUT_ME_LENGTH
    ) {
      profile.myDetails[0].about_me = profile.myDetails[0].about_me.slice(
        0,
        MAX_ABOUT_ME_LENGTH
      );
    }

    if (profile?.description?.length > MAX_ABOUT_ME_LENGTH) {
      profile.description = profile.description.slice(0, MAX_ABOUT_ME_LENGTH);
    }

    profiles.push(profile);
  }

  return data;
}

export const state = () => ({
  dailySlider: [],
  newSlider: [],
  onlineSlider: [],
  statusesSlider: [],
  lastReviews: [],

  girlOfTheDay: [],
  qmCarouselData: [],
  femaleCarouselData: [],
  newCarouselData: [],
  transgenderCarouselData: [],
  maleCarouselData: [],

  dubaiFemalesCount: 0,
  dubaiAllCount: 0,

  // v2
  latest_photo: [],
  latest_video: [],
  location_slider: [],
  testimonials: [],
  dictionary: [],
  reviews_count: 0,
});

export const mutations = {
  setDailySlider(state, users) {
    state.dailySlider = processAboutMeForProfiles(users);
  },

  setNewSlider(state, users) {
    state.newSlider = processAboutMeForProfiles(users);
  },

  setOnlineSlider(state, users) {
    state.onlineSlider = processAboutMeForProfiles(users);
  },

  setStatusesSlider(state, users) {
    state.statusesSlider = processAboutMeForProfiles(users);
  },

  setLastReviews(state, reviews) {
    state.lastReviews = processAboutMeForProfiles(reviews).map((r) => {
      if (r?.review?.text.length > MAX_REVIEW_LENGTH) {
        r.review.text = r.review.text.slice(0, MAX_REVIEW_LENGTH) + "...";
      }
      return r;
    });
  },

  setViewedProfiles(state, profiles) {
    state.viewedProfiles = processAboutMeForProfiles(profiles);
  },

  setGirlOfTheDay(state, data) {
    state.girlOfTheDay = processAboutMeForProfiles(data);
  },

  setQmCarouselData(state, data) {
    state.qmCarouselData = processAboutMeForProfiles(data);
  },

  setFemaleCarouselData(state, data) {
    state.femaleCarouselData = processAboutMeForProfiles(data);
  },

  setNewCarouselData(state, data) {
    state.newCarouselData = processAboutMeForProfiles(data);
  },

  setTransgenderCarouselData(state, data) {
    state.transgenderCarouselData = processAboutMeForProfiles(data);
  },

  setMaleCarouselData(state, data) {
    state.maleCarouselData = processAboutMeForProfiles(data);
  },

  setDubaiFemalesCount(state, data) {
    state.dubaiFemalesCount = data;
  },

  setDubaiAllCount(state, data) {
    state.dubaiAllCount = data;
  },

  // v2

  setLatestPhoto(state, data) {
    state.latest_photo = data;
  },

  setLatestVideo(state, data) {
    state.latest_video = data;
  },

  setLocationSlider(state, data) {
    state.location_slider = data;
  },

  setTestimonials(state, data) {
    state.testimonials = data;
  },

  setDictionary(state, data) {
    state.dictionary = data?.sort((a, b) => a.order - b.order) || [];
  },

  setReviewsCount(state, data) {
    state.reviews_count = data;
  },
};

export const getters = {
  dailySlider: (state) => state.dailySlider,

  newSlider: (state) => state.newSlider,

  onlineSlider: (state) => state.onlineSlider,

  statusesSlider: (state) => state.statusesSlider,

  getLastReviews: (state) => state.lastReviews,

  getGirlOfTheDayData: (state) => state.girlOfTheDay,
  getQmCarouselData: (state) => state.qmCarouselData,
  getFemaleCarouselData: (state) => state.femaleCarouselData,
  getNewCarouselData: (state) => state.newCarouselData,
  getTransgenderCarouselData: (state) => state.transgenderCarouselData,
  getMaleCarouselData: (state) => state.maleCarouselData,
  getDubaiFemalesCount: (state) => state.dubaiFemalesCount,
  getDubaiAllCount: (state) => state.dubaiAllCount,
  // v2
  getLatestPhoto: (state) => state.latest_photo,
  getLatestVideo: (state) => state.latest_video,
  getLocationSlider: (state) => state.location_slider,
  getTestimonials: (state) => state.testimonials,
  getDictionary: (state) => state.dictionary,
  getReviewsCount: (state) => state.reviews_count,
};

export const actions = {
  async getSliderData({ commit, rootGetters }, gender) {
    try {
      const currentCity = rootGetters["global/getCurrentCity"];
      const {
        day: dailyUsers,
        new: newUsers,
        onlne: onlineUsers,
        last_staus: statusesUsers,
        last_reviews,
      } = await this.$axios.$get("/api/models/main_page", {
        params: {
          gender,
          city_id: currentCity?.id,
        },
      });

      commit("setLastReviews", last_reviews);
      commit("setDailySlider", dailyUsers);
      commit("setNewSlider", newUsers);
      commit("setOnlineSlider", onlineUsers);
      commit("setStatusesSlider", statusesUsers);
    } catch (error) {
      console.log(error);

      commit("setDailySlider", []);
      commit("setNewSlider", []);
      commit("setOnlineSlider", []);
      commit("setStatusesSlider", []);
    }
  },

  async getMainPageData({ commit }) {
    try {
      const {
        girl_of_the_day,
        qm,
        top_female,
        new: newCarouselData,
        shemale,
        male,
        dubai_all_count,
        last_status,
        last_reviews,
        filters_top,
        // v2
        latest_photo,
        latest_video,
        location_slider,
        testimonials,
        reviews_count,
      } = await this.$axios.$get("/api/models/main-page");

      const dictionary = await this.$axios.$get("/api/dictionary");

      commit("menus/setNumberModelsFilter", filters_top, { root: true });

      commit("setGirlOfTheDay", girl_of_the_day);
      commit("setQmCarouselData", qm);
      commit("setFemaleCarouselData", top_female);
      commit("setNewCarouselData", newCarouselData);
      commit("setTransgenderCarouselData", shemale);
      commit("setMaleCarouselData", male);
      commit("setDubaiAllCount", dubai_all_count);
      commit("setStatusesSlider", last_status);
      commit("setLastReviews", last_reviews);
      // v2
      commit("setLatestPhoto", latest_photo);
      commit("setLatestVideo", latest_video);
      commit("setLocationSlider", location_slider);
      commit("setTestimonials", testimonials);
      commit("setDictionary", dictionary);
      commit("setReviewsCount", reviews_count);
    } catch (error) {
      console.log(error);
    }
  },
};
